import React from 'react';
import DepartmentModal from  './modals/departmentModal';
import ScheduleModal from './modals/scheduleModal';
import AgentDefaultSettingModal from './modals/agentDefaultSettingModal';
import AgentUnavailableCodesModal from './modals/agentUnavailableCodesModal';
import CallDispositionCodesModal from './modals/callDispositionCodesModal';
import CenterRoutingPoliciesModal from './modals/centerRoutingPoliciesModal';

const EnterpriseSettingsAlt = () => (
  	<div className="container full-card">
		<div className="enterprise-settings card">
			<h2>Enterprise Settings</h2>
			<div className="features">
				<div className="feature">Departments <a className="btn">Edit Settings</a></div>
				<div className="feature">Enterprise Directory <a className="btn">Edit Settings</a></div>
				<div className="feature">Time Schedules <a className="btn">Edit Settings</a></div>
			</div>
			<h2>Contact Center</h2>
			<div className="features">
				<div className="feature">Agent Default Settings <a className="btn">Edit Settings</a></div>
				<div className="feature">Agent Unavailable Codes <a className="btn">Edit Settings</a></div>
				<div className="feature">Call Disposition Codes <a className="btn">Edit Settings</a></div>
				<div className="feature">Contact Center Routing Policies <a className="btn">Edit Settings</a></div>
			</div>
		</div>
	</div>
)

export default EnterpriseSettingsAlt;
				// <DepartmentModal type="ADD" />