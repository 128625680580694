import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { Switch } from '@progress/kendo-inputs-react-wrapper';
import _ from 'lodash';

class AgentDefaultSettingModal extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.changeEnableGuardTime = this.changeEnableGuardTime.bind(this);
    this.changeForceUnavailable3 = this.changeForceUnavailable3.bind(this);
    this.state = { showModal:false, checkForceUnavailable3:false, enableGuardTime:false };
  }

  handleClose() {
    this.setState({...this.state, showModal: false });
  }

  handleShow() {
    this.setState({...this.state, showModal: true });
  }

  changeEnableGuardTime(event){
    this.setState({...this.state, enableGuardTime: event.checked });
  }

  changeForceUnavailable3(event){
    this.setState({...this.state, checkForceUnavailable3: event.checked });
  }


	componentDidMount() {		
	}

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Agent Default Settings</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Agent Default Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laudantium magni id aliquid, sint blanditiis delectus esse ullam, eligendi recusandae ratione tempore ipsa illum error hic.</p>
              <div className="form-group">
                <label>Use Guard Time Settings </label>
                <div className="form-control">
                  <label className="custom-control custom-radio">
                  <input id="radio1" name="radio" type="radio"/>
                    <span >Default </span>
                  </label> 
                  <label className="custom-control custom-radio">
                    <input id="radio1" name="radio" type="radio"/>
                    <span>Enterprise</span>
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label className="switch right">
                  <input type="checkbox" data-toggle="collapse" data-target="#ads1" />
                  <span className="slider round"></span>
                </label>
                Enable guard time
                <div className="hidden-group collapse" id="ads1">
                  <label >For how many seconds</label>
                  <select className="form-control">
                    {_.times(25, i =>
                      <option key={i} defaultValue={i}>{i+1}</option>
                    )}
                  </select>
                </div>
              </div>
              <div className="form-group">
                <label>Use Agent Unavailable Settings</label>
                <div className="form-control">
                  <label className="custom-control custom-radio">
                  <input id="radio2" name="radio2" type="radio"/>
                    <span >Default </span>
                  </label> 
                  <label className="custom-control custom-radio">
                    <input id="radio2" name="radio2" type="radio"/>
                    <span>Enterprise</span>
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label className="switch right">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                Force Agent to unavailable on do not disturb activation
              </div>
              <div className="form-group">
                <label className="switch right">
                  <input type="checkbox" />
                  <span className="slider round"></span>
                </label>
                Force Agent to unavailable on personal calls
              </div>
              <div className="form-group">
                <label className="switch right">
                  <input type="checkbox" data-toggle="collapse" data-target="#ads2" />
                  <span className="slider round"></span>
                </label>
                Force Agent to unavailable after
                <div className="hidden-group collapse" id="ads2">
                  <label >For how many consecutive bounces</label>
                  <select className="form-control">
                    {_.times(5, i =>
                      <option key={i} defaultValue={i}>{i+1}</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <Button className="btn">Save</Button>
                <Button className="btn" click={this.handleClose}>Cancel</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default AgentDefaultSettingModal;
