import React from 'react';
import DepartmentModal from  './modals/departmentModal';
import EnterpriseDirectoryModal from './modals/enterpriseDirectoryModal';
import ScheduleModal from './modals/scheduleModal';
import AgentDefaultSettingModal from './modals/agentDefaultSettingModal';
import AgentUnavailableCodesModal from './modals/agentUnavailableCodesModal';
import CallDispositionCodesModal from './modals/callDispositionCodesModal';
import CenterRoutingPoliciesModal from './modals/centerRoutingPoliciesModal';
import AnywhereModal from './modals/anywhereModal';
import GroupFqdnModal from './locations/modals/groupFqdnModal';
import RemoteOfficeModal from './modals/remoteOfficeModal';
import PreAlertModal from './modals/preAlertModal';
import CustomRingbackModal from './modals/customRingbackModal';
import VirtualNumberModal from './modals/virtualNumberModal';

const EnterpriseSettings = () => (
  	<div className="container full-card">
		<div className="enterprise-settings card">
			<h1>Enterprise Settings</h1>
			<div className="settings-row">
				<AgentDefaultSettingModal/>
				<AgentUnavailableCodesModal/>
				<AnywhereModal />
				<CallDispositionCodesModal/>
				<CenterRoutingPoliciesModal/>
				<CustomRingbackModal />
				<DepartmentModal />
				<EnterpriseDirectoryModal />
				<GroupFqdnModal />
				<PreAlertModal />
				<RemoteOfficeModal />
				<ScheduleModal type="BUSINESS_HOURS"/>
				<ScheduleModal type="HOLIDAY_SCHEDULE"/>
				<VirtualNumberModal />
			</div>
		</div>
	</div>
)

export default EnterpriseSettings;