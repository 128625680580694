import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { Switch } from '@progress/kendo-inputs-react-wrapper';
import _ from 'lodash';

class CenterRoutingPoliciesModal extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = { showModal:false };
  }

  handleClose() {
    this.setState({...this.state, showModal: false });
  }

  handleShow() {
    this.setState({...this.state, showModal: true });
  }
  
	componentDidMount() {		
	}

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Contact Center Routing Policies</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Contact Center Routing Policies</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laudantium magni id aliquid, sint blanditiis delectus esse ullam, eligendi recusandae ratione tempore ipsa illum error hic.</p>
              <div className="form-group">
                <label>Routing Policy</label>
                <div className="form-control">
                  <label><input type="radio" name="radio" id="one" defaultValue="multiple" /> Longest Wait Time</label>
                  <label><input type="radio" name="radio" id="one" defaultValue="multiple" /> Priority Order</label>
                </div>
              </div>
              <hr/>
              <div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Contact Center Name</th>
                      <th>Priority</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Call Center 123322
                      </td>
                      <td>
                        <input type="text" className="form-control" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <Button className="btn">Save</Button>
                <Button className="btn" click={this.handleClose}>Cancel</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default CenterRoutingPoliciesModal;
