import React, { Component } from 'react';
import * as settings from './../scripts/session';
import * as config from './../requests/config'
import ReactDom from 'react-dom';
import { DataLoadingSpinner } from './../components/spinners';
import { connect } from 'react-redux';
import FQDNSetupModal from './locations/modals/fQDNSetupModal';
import * as session from "../scripts/session";

// 1. Group Features Page - "MSP_GROUP"
// 2. Settings Feature Page - "MSP_SUBSCRIBER" + tn param
// 3. Call Center Page - "MSP_CC"
// 4. Enterprise Page - "MSP_ENTERPRISE"
// 5. Support page - "MSP_SUPPORT"
// 6. Training page - "MSP_TRAINING"
// 7. Payments Pages - "MSP_PAYMENT"
class IframeSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showBillingMessage: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updatePageIframe = this.updatePageIframe.bind(this);
  }
  componentWillReceiveProps() {
    if (!this.state.loading)
      this.setState({ loading: true })
  }

 
  componentDidUpdate() {
    //need to replace this with checking if token is valid.
    var user = config.clientApp.getAccount()
    if (this.state.loading && (user || sessionStorage.RPXLogin)) {
      this.updatePageIframe();
    }
    else if (this.state.loading) {
      this.setState({ loading: false })
    }
  }

  componentDidMount() {
    var user = config.clientApp.getAccount();
    let iframekey = this.props.match.params.page;

    if (iframekey === 'MSP_PAYMENT' && (session.IsWholesale() ||
      sessionStorage.userEnterpriseNumber === "3904000825" ||
      sessionStorage.hasBillingAdminPermission == "false")
    ) {
      this.props.history.push("/");
    }
    if (iframekey === 'MSP_PAYMENT' && process.env.REACT_APP_BILLING_MESSAGE && process.env.REACT_APP_BILLING_MESSAGE !== '')
      this.setState({ showBillingMessage: true });
    // else if (iframekey === "MSP_ENTERPRISE" && (sessionStorage.userTypeId === "1107" || sessionStorage.userTypeId === "1105")) {
    //   this.fetchTermsAndConditions();
    // }
    else if (user || sessionStorage.RPXLogin) {
      this.updatePageIframe();
    }
  }

  updatePageIframe = () => {
    let timeout = setInterval(() => {
      try {
        this.refs.submitform.submit();

        let iframeChecker = setInterval(() => {
          try {
            //do not remove this. this will throw a  cross-origin exception when the body is loaded. 
            //In the catch it will stop the setInterval and hide the loading spinner
            ReactDom.findDOMNode(this.refs.iframe).contentDocument.body;
          } catch (e) {
            clearInterval(iframeChecker);
            this.setState({ loading: false });
          }

        }, 2000);
      } catch (e) {
      }
      clearInterval(timeout);
    }, 1000);


  }

  handleSubmit() {
    console.log("handleSubmit");
  }

  /*********************************************************************
   * This function supplies a good title attribute for iframes and 
   * helps us comply with accessibility standards.
   ********************************************************************/
  getiFrameTitle = (iframekey) => {
    switch (iframekey) {
      case "MSP_GROUP":
        return "Group Features Page";
      case "MSP_SUBSCRIBER":
        return "Settings Feature Page";
      case "MSP_CC":
        return "Call Center Page";
      case "MSP_ENTERPRISE":
        return "Enterprise Page";
      case "MSP_SUPPORT":
        return "Support Page";
      case "MSP_TRAINING":
        return "Training Page";
      case "MSP_PAYMENT":
        return "Payments Page";
      default:
        return "MSP Page";
    }
  }

  render() {
    // console.log("renderring MSP page");
    // console.log('component props...')
    let iframekey = this.props.match.params.page;
    let actionUrl = config.baseUrl + "IFrame.aspx?page=" + this.props.match.params.page;
    let locationAccNumber = this.props.match.params.LocationAccountNumber;
    let tn = this.props.match.params.tn;
    if (tn) {
      iframekey += "" + tn;
      actionUrl = actionUrl + "&tn=" + tn;
    }
    if (iframekey !== 'MSP_ENTERPRISE' && locationAccNumber) {
      actionUrl += '&locationID=' + locationAccNumber;
    }
    const VendorId = sessionStorage.getItem('vendorId') || 0;
    iframekey += "" + VendorId;
    actionUrl = actionUrl + "&VendorId=" + VendorId;
    if (this.state.showBillingMessage === false)
      return (
        <div className="full-card container">
          <div className="iframe-holder card">
            {this.getEnterpriseSetting()}
            {this.getGroupSetting(locationAccNumber)}
            {this.state.loading ? <DataLoadingSpinner ref="dataLoadingSpinner" className='load spinner' style={{ top: 20 }} /> : ""}
            <iframe title={this.getiFrameTitle(iframekey)} key={iframekey} name="iFrame" ref="iframe" className="iframe-card iframe-settings"></iframe>
            <form onSubmit={this.handleSubmit} ref="submitform" target="iFrame" method="POST"
              className="hidden"
              action={actionUrl}>
              <input type="text" name="access_token" defaultValue={settings.urlEncodedToken()} />
              <input type="text" name="x_policy" defaultValue={settings.storedXPolicy()} />
              <input type="text" name="Username" defaultValue={settings.parsedUsername()} />
              <input type="text" name="EnterpriseId" defaultValue={settings.getEnterpriseId()} />
              <input type="text" name="expiration" defaultValue={settings.getExpirationForEmb()} />
              <input type="text" name="EmulatedUser" defaultValue={settings.parsedEmulatedUser()} />
              <input type="submit" />
            </form>
          </div>
        </div>
      );
    else {
      let billingMessage = process.env.REACT_APP_BILLING_MESSAGE;
      return (
        <div className="full-card container">
          <div className='billing_maintenance'>
            <h4>{billingMessage}</h4>
          </div>
        </div>
      );
    }
  }

  getEnterpriseSetting = () => {
    ////// Commenting this out until devices are released
    // console.log(this.props.match.params.page)
    if (this.props.match.params.page === "MSP_ENTERPRISE") {
      return <div className="settings-row">
        <FQDNSetupModal />
      </div>
    }
  }


  getGroupSetting = (locationAccNum) => {
    //// Commenting this out until devices are released
    // console.log(this.props.match.params.page)
    // if(this.props.match.params.page !== "MSP_GROUP") return "";
    return "";
    // if (helper.showEditGroupFqdnBtn() || helper.showEditGroupVlanBtn()) {
    //   return (
    //     <div className="settings-row">
    //       <span className={helper.showEditGroupFqdnBtn() ? 'visible' : 'hidden'}>
    //         <GroupFqdnModal locationAccNum={locationAccNum} />
    //       </span>
    //       <span className={helper.showEditGroupVlanBtn() ? 'visible' : 'hidden'}>
    //         <GroupVLANModal locationAccNum={locationAccNum} />
    //       </span>
    //     </div>
    //   )
    // }    
  }
}

const mapStateToProps = (state) => {
  return {
    AccountNumber: state.reducer.AccountNumber,
    loggedIn: state.reducer.loggedIn
  }
}

export default connect(mapStateToProps)(IframeSettings);