import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@progress/kendo-buttons-react-wrapper';
import { Switch } from '@progress/kendo-inputs-react-wrapper';
import _ from 'lodash';

class CallDispositionCodesModal extends Component {

	constructor(props) {
		super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = { showModal:false };
  }

  handleClose() {
    this.setState({...this.state, showModal: false });
  }

  handleShow() {
    this.setState({...this.state, showModal: true });
  }
  
	componentDidMount() {		
	}

  render() {
    return (
      <div className="setting">
        <span onClick={this.handleShow}> 
          <span className="name">Call Disposition Codes</span>
          <a className="icon btn"><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Call Disposition Codes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="CallDispositionCodesModal">
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laudantium magni id aliquid, sint blanditiis delectus esse ullam, eligendi recusandae ratione tempore ipsa illum error hic.</p>
              <div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Active</th>
                      <th>Code</th>
                      <th>Description</th>
                      <th>Edit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="check">
                          <label className="">
                            <input type="checkbox" />
                          </label>
                      </td>
                      <td>Active</td>
                      <td>1</td>
                      <td>Lorem ipsum dolor sit amet, consectetur adipisicing eli</td>
                      <td>
                        <a className="icon btn"  data-toggle="collapse" data-target="#cdc1" ><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
                      </td>
                    </tr>
                    <tr className="collapse" id="cdc1">
                      <td colSpan="5">
                        <div className="table-edit">
                          <a href="" className="red delete">Delete Call Disposition Code</a>
                          <div className="form-group">
                            <label className="switch">
                              <input type="checkbox"/>
                              <span className="slider round"></span>
                            </label>
                            Activate Call Disposition Codes
                          </div>
                          <div className="form-group">
                            <label>Code</label>
                            <input type="text" className="form-control" />
                          </div>
                          <div className="form-group">
                            <label>Description</label>
                            <input type="text" className="form-control" />
                          </div>
                          <div className="btns">
                            <Button className="btn">Save</Button>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td className="check">
                          <label className="">
                            <input type="checkbox" />
                          </label>
                      </td>
                      <td>Active</td>
                      <td>1</td>
                      <td>Lorem ipsum dolor sit amet, consectetur adipisicing eli</td>
                      <td>
                        <a className="icon btn"  data-toggle="collapse" data-target="#cdc2" ><svg width="14" height="14" viewBox="0 0 24 24"><path d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z" transform="scale(0.046875 0.046875)"></path></svg></a>
                      </td>
                    </tr>
                    <tr className="collapse" id="cdc2">
                      <td colSpan="5">
                        <div className="table-edit">
                          <a href="" className="red delete">Delete Call Disposition Code</a>
                          <div className="form-group">
                            <label className="switch">
                              <input type="checkbox"/>
                              <span className="slider round"></span>
                            </label>
                            Activate Call Disposition Codes
                          </div>
                          <div className="form-group">
                            <label>Code</label>
                            <input type="text" className="form-control" />
                          </div>
                          <div className="form-group">
                            <label>Description</label>
                            <input type="text" className="form-control" />
                          </div>
                          <div className="btns">
                            <Button className="btn">Save</Button>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="5">
                        <div className="btns">
                          <button className="btn" data-toggle="collapse" data-target="#cdc2">Add Call Disposition Code</button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
              <hr />
              <div className="btns">
                <Button className="btn">Save</Button>
                <Button className="btn" click={this.handleClose}>Cancel</Button>
              </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
};

export default CallDispositionCodesModal;
