import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fetchData } from "./../../requests/api_calls";
import { store } from "./../../store/store";
import { connect } from "react-redux";
import { userIsAdmin, userIsContactManager, userIsSuperAdmin } from "./../../scripts/helper";
import * as actions from "./../../store/actions";
import * as icons from "./../../scripts/icons";
import * as session from "../../scripts/session";
import { checkBillingAccount } from "../../utility/utility";

const IsWholesaleUser = session.IsWholesale();

class AdminMenu extends Component {
    constructor() {
        super();
        this.state = {
            permissions: {},
            hasTrunking: false,
            hasContactCenter: false,
            hasMacd: false,
            hasDevices: false,
            hasIpTrunk: false,
            hasTicketing: "NONE",
            hasVmUsageReport: false,
            Header: "Admin Tools",
            hasAtLeastOneAdminLink: false,
            permissionsRetrieved: false,
        };
    }

    componentDidMount() {
        // avoids 401 error by not calling for non admin
        //TODO - limit to one API call for access to various pages
        if (userIsAdmin()) {
            this.buildPermissions();
            this.setState({ hasAtLeastOneAdminLink: true });
        } else {
            this.buildSubscriberPermissions();
        }
    }

    componentWillReceiveProps = (props) => {
        let state = this.state;
        if (
            state.hasContactCenter !== props.hasContactCenter ||
            state.hasTrunking !== props.hasTrunking ||
            state.hasDevices !== props.hasDevices ||
            state.hasDevices !== props.hasDevices ||
            state.hasMacd !== props.hasMacd ||
            state.hasIpTrunk !== props.hasIpTrunk ||
            state.hasTicketing !== props.hasTicketing ||
            state.permissionsRetrieved !== props.permissionsRetrieved
        ) {
            this.setState({
                hasContactCenter: props.hasContactCenter,
                hasTrunking: props.hasTrunking,
                hasMacd: props.hasMacd,
                hasDevices: props.hasDevices,
                hasIpTrunk: props.hasIpTrunk,
                hasTicketing: props.hasTicketing,
                permissionsRetrieved: props.permissionsRetrieved,
            });
        }
    };

    buildSubscriberPermissions = async () => {
        let hasAtLeastOneAdminLink = false;
        let ID = sessionStorage.PBXUserID ? sessionStorage.PBXUserID : "";
        let accNum = this.props.LocationAccountNumber;

        let apiPath = `admin/GetEnterpriseLeftMenuLayout?PBXUserId=${ID}&LocationAccountNumber=${accNum}`;
        fetchData(apiPath)
            .then(async (data) => {
                await this.setState({
                    hasAtLeastOneAdminLink: hasAtLeastOneAdminLink,
                    Header: "Admin Tools",
                    permissions: data,
                    hasMacd: data.AllowMACDs == 1 ? true : false,
                    hasDevices: data.NEPSSettings.NepsPermitted
                });
                //
                if (data.TicketingPermission !== "NONE") {
                    hasAtLeastOneAdminLink = true;
                }
                if (sessionStorage.IsWholesale === "true") {
                    hasAtLeastOneAdminLink = true;
                }
                //
                sessionStorage.setItem(
                    "hasCallRecordingMenu",
                    data.HasCallRecordingMenu == 1 ? true : false
                );
                sessionStorage.setItem(
                    "hasCallRecordingService",
                    data.HasCallRecordingService == 1 ? true : false
                );
                sessionStorage.setItem(
                    "hasContactCenter",
                    data.HasContactCenter == 1 ? true : false
                );
                if (data && !IsWholesaleUser) {
                    sessionStorage.setItem("hasTicketing", data.TicketingPermission);
                }
                if (data.TicketingPermission !== "NONE") {
                    hasAtLeastOneAdminLink = true;
                }
                if (sessionStorage.IsWholesale === "true") {
                    // wrote condition this way (instead of using IsWholesaleUser var at the top) because there seems to be async issue when the string is converted to a boolean
                    hasAtLeastOneAdminLink = true;
                }
                store.dispatch({ type: actions.PERMISSIONS_UPDATED });
            })
            .catch((err) => console.error(err));

    };

    buildPermissions = async () => {
        let ID = sessionStorage.PBXUserID ? sessionStorage.PBXUserID : "";
        let accNum = this.props.LocationAccountNumber ? this.props.LocationAccountNumber : "";

        let apiPath = `admin/GetEnterpriseLeftMenuLayout?PBXUserId=${ID}&LocationAccountNumber=${accNum}`;
        await fetchData(apiPath)
            .then(async (data) => {
                await this.setState({
                    permissions: data,
                    hasMacd: data.AllowMACDs == 1 ? true : false,
                    hasDevices: data.NEPSSettings.NepsPermitted,
                });
                //Start MACD
                // if any MACD Permissions equal 1, then show the "Service Changes" link
                // unless permissions that are not valid for Wholesale users are included
                let permsObj = {
                    addServices:
                        data && data.MACDSettings.Add_Services && !IsWholesaleUser
                            ? data.MACDSettings.Add_Services
                            : 0,
                    assignUserAddons:
                        data && data.MACDSettings.Assign_UserAddons
                            ? data.MACDSettings.Assign_UserAddons
                            : 0,
                    changeTfTerminatingNumber: 0,
                    changeVnTerminatingNumber:
                        data && data.MACDSettings.ChangeVN_TerminatingNumber
                            ? data.MACDSettings.ChangeVN_TerminatingNumber
                            : 0,
                    changeAddress: 0,
                    changeBillingInfo: 0,
                    changeBlock:
                        data && data.MACDSettings.Change_Block
                            ? data.MACDSettings.Change_Block
                            : 0,
                    changeCNAM:
                        data && data.MACDSettings.Change_CNAM
                            ? data.MACDSettings.Change_CNAM
                            : 0,
                    changeCallPathCount: 0,
                    changeExtension:
                        data && data.MACDSettings.Change_Extension && !IsWholesaleUser
                            ? data.MACDSettings.Change_Extension
                            : 0,
                    changeLineType:
                        data && data.MACDSettings.Change_LineType && !IsWholesaleUser
                            ? data.MACDSettings.Change_LineType
                            : 0,
                    changeListing:
                        data && data.MACDSettings.Change_Listing && !IsWholesaleUser
                            ? data.MACDSettings.Change_Listing
                            : 0,
                    changeSeatType:
                        data && data.MACDSettings.Change_SeatType && !IsWholesaleUser
                            ? data.MACDSettings.Change_SeatType
                            : 0,
                    disconnectServices: 0,
                    unassignUserAddons:
                        data && data.MACDSettings.Unassign_UserAddons
                            ? data.MACDSettings.Unassign_UserAddons
                            : 0,
                    ipTrunkCallPathBurst:
                        data && data.MACDSettings.Change_IPTrunking_CallPathPlan_and_Count
                            ? data.MACDSettings.Change_IPTrunking_CallPathPlan_and_Count
                            : 0,
                };

                sessionStorage.setItem(
                    "hasTrunk",
                    data.HasTrunking == 1 ? true : false
                );
                sessionStorage.setItem(
                    "hasContactCenter",
                    data.HasContactCenter == 1 ? true : false
                );
                sessionStorage.setItem("MacdPerms", JSON.stringify(permsObj));
                // if any NEPS permissions are 1, show the "Devices" link
                sessionStorage.setItem(
                    "DevicePerms",
                    JSON.stringify(data.NEPSSettings)
                );
                sessionStorage.setItem(
                    "hasDevices",
                    data.NEPSSettings.NepsPermitted == true ? true : false
                );

                //Start IP Trunking
                if (data) {
                    sessionStorage.setItem("hasIpTrunk", data.HasIpTrunking == 1 ? true : false);
                }
                if (data) {
                    sessionStorage.setItem("hasBillingAdminPermission", data.BillingAdminPermission == 1 ? true : false);
                }
                // Start Ticketing
                // Returns "NONE", "READONLY", or "FULL". ***
                // I did some quick testing with new ticketing permission.
                // For a SuperAdmin all works as before.
                // But, for a user login you should probably call the new API to get permission, and if not "NONE" you should add the ticketing menu to the left menu bar.
                // Then enable update/create/delete operations on tickets only of the permission is "FULL".
                if (data && !IsWholesaleUser) {
                    sessionStorage.setItem("hasTicketing", data.TicketingPermission);
                }
                //End Ticketing
                sessionStorage.setItem("hasMacd", data.AllowMACDs == 1 ? true : false)
                // Start VM Usage Report
                sessionStorage.setItem(
                    "hasVmUsageReport",
                    data.ShowVoiceMailReport == 1 ? true : false
                );

                // End VM Usage Report

                //Start Ent Trunking
                sessionStorage.setItem(
                    "hasEntTrunking",
                    data.ShowEnterpriseTrunk === 1 ? true : false
                );

                sessionStorage.setItem(
                    "hasActiveDirectory",
                    data.ShowActiveDirectory === 1 ? true : false
                );

                //End AD Sync
                sessionStorage.setItem(
                    "hasTeams",
                    data.MSTeamsRoutingType == "NONE" ? false : true
                );

                sessionStorage.setItem(
                    "hasCallRecordingMenu",
                    data.HasCallRecordingMenu == 1 ? true : false
                );
                sessionStorage.setItem(
                    "hasCallRecordingService",
                    data.HasCallRecordingService == 1 ? true : false
                );

                sessionStorage.setItem(
                    "hasCircuits",
                    data.EnterpriseType === "VOICE" ? false : true
                );

                sessionStorage.setItem(
                    "hasWebEx",
                    data.IsWebExAllowed == 1 ? true : false
                );

                // Start Locations
                await fetchData("admin/Locations")
                    .then((locations) => {
                        //
                        sessionStorage.setItem("locationList", JSON.stringify(locations));
                        //
                        let ipTrunkTest = locations.filter((resp) => resp.IsIPTrunking === false);
                        if (!ipTrunkTest || ipTrunkTest.length === 0) {
                            sessionStorage.setItem("isIpTrunkingOnly", true);
                        }
                        store.dispatch({ type: actions.IP_TRUNKING_ONLY_SET });
                    })
                    .catch((err) => {
                        console.error(err);
                        store.dispatch({ type: actions.IP_TRUNKING_ONLY_SET });
                    });
                //End Locations
            })
            .catch((err) => console.error(err));
        store.dispatch({ type: actions.PERMISSIONS_UPDATED });
    };

    serviceChangesLink = () => {
        if (this.state.permissions.AllowMACDs) {
            return (
                <Link
                    to={{ pathname: "/macd", state: {} }}
                    className="macd-link nav-item"
                >
                    <span className="name">Service Changes</span>
                </Link>
            );
        } else return null;
    };

    menuLink = (path, icon, label) => {
        return (
            <Link to={path} className="nav-item">
                <span className="icon">{icon}</span>
                <span className="name">{label}</span>
            </Link>
        );
    };

    trunkingLink = () => {
        if (this.state.permissions.HasTrunking) {
            return (
                <Link to={{ pathname: "/sips", state: {} }} className="nav-item">
                    <span className="icon">
                        <svg width="12" height="12" viewBox="0 0 23 23" alt="trunking icon">
                            <path
                                d="m416 192l-320 0c-17 0-32 16-32 32l0 64c0 16 16 32 32 32l320 0c16 0 32-16 32-32l0-64c0-16-16-32-32-32z m-288 96l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m96 64l-320 0c-17 0-32 16-32 32l0 64c0 16 16 32 32 32l320 0c16 0 32-16 32-32l0-64c0-16-16-32-32-32z m-288 96l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m96-416l-320 0c-17 0-32 16-32 32l0 64c0 16 16 32 32 32l320 0c16 0 32-16 32-32l0-64c0-16-16-32-32-32z m-288 96l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m64 0l-32 0 0-64 32 0z m96-32l-32 0 0-32 32 0z"
                                transform="scale(0.046875 0.046875)"
                            />
                        </svg>
                    </span>
                    <span className="name">Trunking</span>
                </Link>
            );
        } else return null;
    };

    LocationsLink = (path, icon, label) => {
        return (
            <Link to={path} className="nav-item">
                <span className="icon">{icon}</span>
                <span className="name">{label}</span>
            </Link>
        );
    };

    devicesLink = () => {
        let { NEPSSettings } = this.state.permissions;
        if (NEPSSettings) {
            if (NEPSSettings.NepsPermitted) {
                return this.menuLink("/devices", icons.devices, "Devices");
            } else return null;
        } else return null;
    };

    contactCenterLink = () => {
        if (userIsContactManager() || this.state.permissions.HasContactCenter) {
            return this.menuLink(
                "/iframe_page/MSP_CC",
                icons.contactCenter,
                "Contact Center"
            );
        } else return null;
    };

    billingLink = () => {
        if (
            !session.IsWholesale() &&
            sessionStorage.userEnterpriseNumber !== "3904000825" &&
            this.state.permissions.BillingAdminPermission == 1
        ) {
            return this.menuLink(
                "/iframe_page/MSP_PAYMENT",
                icons.billing,
                "Billing"
            );
        } else return null;
    };

    ipTrunkingLink = () => {
        if (this.state.permissions.HasIpTrunking) {
            return this.menuLink("/ip_trunking", icons.trunking, "IP Trunking");
        }
    };

    ticketingLink = () => {
        if (
            this.state.permissions.TicketingPermission && this.state.permissions.TicketingPermission !== "NONE" &&
            (this.state.permissions.EnterpriseType === "DATA" || this.state.permissions.EnterpriseType === "VOICE" || this.state.permissions.EnterpriseType === "MIXED")
        ) {
            return this.menuLink("/tickets_all", icons.ticketing, "Support Tickets");
        }
    };

    documentsLink = () => {
        if (sessionStorage.IsWholesale === "true") {
            return this.menuLink("/documents", icons.documents, "Documents");
        } else return;
    };

    addressBookLink = () => {
        if (
            sessionStorage.IsWholesale === "true" &&
            this.state.permissionsRetrieved &&
            !sessionStorage.isIpTrunkingOnly
        ) {
            return this.menuLink(
                "/enterprise_contact_directory",
                icons.addressBook,
                "Contact Directory"
            );
        } else return;
    };

    entTrunkLink = () => {
        if (this.state.permissions.ShowEnterpriseTrunk) {
            return this.menuLink(
                "/enterprise-trunking",
                icons.entTrunk,
                "Enterprise Trunking"
            );
        } else return;
    };

    adLink = () => {
        if (this.state.permissions.ShowActiveDirectory) {
            return this.menuLink(
                `/active-directory`,
                icons.addressBook,
                "Single Sign On"
            );
        } else return;
    };

    msTeamsLink = () => {
        if (this.state.permissions.MSTeamsRoutingType) {
            if (this.state.permissions.MSTeamsRoutingType !== "OPERATOR_CONNECT" && this.state.permissions.MSTeamsRoutingType !== "NONE") {
                return this.menuLink(
                    `/microsoft-teams`,
                    icons.msTeams,
                    "Microsoft Teams"
                );
            }
        } else return;
    };

    ShowCallRecordingLink = () => {
        const userTypeIdViewOnly = ['1102', '1103', '1104', '1105', '1107'];
        let userTypeId = [sessionStorage.userTypeId];
        let val = userTypeId.every(userTypeId => { return userTypeIdViewOnly.includes(userTypeId) });
        return val;
    }

    callRecordingLink = () => {
        const showCallRecordingLink = this.ShowCallRecordingLink();
        if ((showCallRecordingLink === true || showCallRecordingLink === "true") && this.state.permissions.HasCallRecordingMenu)
            return this.menuLink(
                `/call-recording`,
                icons.callRecording,
                "Call Recording"
            );
    };

    webExLink = () => {
        if (this.state.permissions.IsWebExAllowed) {
            return this.menuLink(`/webex`, icons.webex, "WebEx");
        }
    };

    circuitsMonitioringLink = () => {
        if (this.state.permissions.EnterpriseType && this.state.permissions.EnterpriseType !== "VOICE") {
            return this.menuLink(`/circuits-monitoring`, icons.circuits, "Circuits");
        }
    };

    circuitDevicesLink = () => {
        if (this.state.permissions.EnterpriseType && this.state.permissions.EnterpriseType !== "VOICE") {
            return this.menuLink(
                `/circuit-devices`,
                icons.circuits,
                "Circuit Devices"
            );
        }
    };

    circuitMapLink = () => {
        if (this.state.permissions.EnterpriseType && this.state.permissions.EnterpriseType !== "VOICE") {
            return this.menuLink(
                "/service-locations",
                icons.circuitsMap,
                "Service Locations"
            )
        }
    }

    mfaAdminTool = () => {
        if (sessionStorage.enableMFA === "true" &&
            (   // super admins
                sessionStorage.userTypeId === "1107" ||
                sessionStorage.userTypeId === "1105" ||
                sessionStorage.userTypeId === "1104" ||
                sessionStorage.userTypeId === "1103" ||
                sessionStorage.userTypeId === "1102" ||
                // group admins
                sessionStorage.userTypeId === "1106" ||
                sessionStorage.userTypeId === "1109" ||
                sessionStorage.userTypeId === "1114" ||
                sessionStorage.userTypeId === "1115" ||
                sessionStorage.userTypeId === "1116")
        ) {
            return this.menuLink(
                "/manage-mfa-configuration",
                icons.mfa,
                "Manage MFA"
            )
        }
    }

    operatorConnectLink = () => {
        //if (sessionStorage.userRole === "SUPER_ADMIN" && sessionStorage.userTypeId === "1107") {
        return this.menuLink(
            //"/operator-connect",
            "/operator-connectv1",
            icons.mfa,
            "Operator Connect"
        )
        //}
    }

    render() {
        if (userIsAdmin()) {
            if (checkBillingAccount()) {
                return (
                    <div>
                        <div
                            className="nav-head"
                            style={{
                                display:
                                    this.state.hasAtLeastOneAdminLink === true ? "block" : "none",
                            }}
                        >
                            {this.state.Header}
                        </div>
                        {this.menuLink("/admin", icons.dashboard, "Dashboard")}
                        {/* {this.billingLink()} */}
                        {!session.IsWholesale() &&
                            sessionStorage.userEnterpriseNumber !== "3904000825" &&
                            this.state.permissions.BillingAdminPermission == 1 && this.menuLink(
                                "/BillingNew",
                                icons.billing,
                                "Billing"
                            )}
                    </div>
                );
            } else {
                return (
                    <div>
                        <div
                            className="nav-head"
                            style={{
                                display:
                                    this.state.hasAtLeastOneAdminLink === true ? "block" : "none",
                            }}
                        >
                            {this.state.Header}
                        </div>
                        {this.menuLink("/admin", icons.dashboard, "Dashboard")}
                        {this.LocationsLink(
                            { pathname: "/locations", state: {} },
                            icons.locations,
                            "Locations & Groups"
                        )}
                        {this.state.permissions.EnterpriseType && this.state.permissions.EnterpriseType !== "DATA" &&
                            this.menuLink(
                                "/services",
                                icons.servicesAndUsers,
                                "Services & Users"
                            )}
                        {this.state.permissions.EnterpriseType && this.state.permissions.EnterpriseType !== "DATA" && this.devicesLink()}
                        {this.state.permissions.EnterpriseType && this.state.permissions.EnterpriseType !== "DATA" && this.trunkingLink()}
                        {this.state.permissions.EnterpriseType && this.state.permissions.EnterpriseType !== "DATA" &&
                            this.menuLink(
                                "/iframe_page/MSP_ENTERPRISE",
                                icons.enterpriseSettings,
                                "Enterprise Settings"
                            )}
                        {this.state.permissions.EnterpriseType && this.state.permissions.EnterpriseType !== "DATA" &&
                            this.contactCenterLink()}
                        {/* {this.billingLink()} */}
                        {!session.IsWholesale() &&
                            sessionStorage.userEnterpriseNumber !== "3904000825" &&
                            this.state.permissions.BillingAdminPermission == 1 && this.menuLink(
                                "/BillingNew",
                                icons.billing,
                                "Billing"
                            )}
                        {this.state.permissions.EnterpriseType && this.state.permissions.EnterpriseType !== "DATA" && this.ipTrunkingLink()}
                        {this.ticketingLink()}
                        {this.state.permissions.EnterpriseType && this.state.permissions.EnterpriseType !== "DATA" && this.documentsLink()}
                        {this.state.permissions.EnterpriseType && this.state.permissions.EnterpriseType !== "DATA" && this.addressBookLink()}
                        {this.state.permissions.EnterpriseType && this.state.permissions.EnterpriseType !== "DATA" && this.entTrunkLink()}
                        {this.state.permissions.EnterpriseType && this.state.permissions.EnterpriseType !== "DATA" && this.adLink()}
                        {this.state.permissions.EnterpriseType && this.state.permissions.EnterpriseType !== "DATA" && this.msTeamsLink()}
                        {this.state.permissions.EnterpriseType && this.state.permissions.EnterpriseType !== "DATA" &&
                            this.callRecordingLink()}
                        {this.state.permissions.EnterpriseType && this.state.permissions.EnterpriseType !== "DATA" && this.webExLink()}
                        {this.circuitsMonitioringLink()}
                        {this.mfaAdminTool()}
                        {this.state.permissions.MSTeamsRoutingType && this.state.permissions.MSTeamsRoutingType == "OPERATOR_CONNECT" && this.operatorConnectLink()}
                        {this.state.permissions.EnterpriseType && this.state.permissions.EnterpriseType !== "DATA" &&
                            this.serviceChangesLink()}
                    </div>
                );
            }
        } else {
            // test w/ non-admin user
            return (
                <div>
                    <div
                        className="nav-head"
                        style={{
                            display:
                                this.state.hasAtLeastOneAdminLink === true ? "block" : "none",
                        }}
                    >
                        {this.state.Header}
                    </div>
                    {!session.IsWholesale() &&
                        sessionStorage.userEnterpriseNumber !== "3904000825" &&
                        this.state.permissions.BillingAdminPermission == 1 && this.menuLink(
                            "/BillingNew",
                            icons.billing,
                            "Billing"
                        )}
                    {this.contactCenterLink()}
                    {this.ticketingLink()}
                    {this.addressBookLink()}
                    {this.documentsLink()}
                    {this.callRecordingLink()}
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        LocationAccountNumber: state.reducer.AccountNumber,
        hasTrunking: state.reducer.hasTrunking,
        hasContactCenter: state.reducer.hasContactCenter,
        hasMacd: state.reducer.hasMacd,
        hasDevices: state.reducer.hasDevices,
        hasIpTrunk: state.reducer.hasIpTrunk,
        hasTicketing: state.reducer.hasTicketing,
        permissionsRetrieved: state.reducer.permissionsRetrieved,
    };
};
export default connect(mapStateToProps)(AdminMenu);